<template>
  <div class="admin-dashboard">
    <div class="dashboard-wrap">
      <!-- 회원 사이트 목록 -->
      <ul class="site-list">
        <li
          v-for="(site, index) in $store.state.ModuleUserSite.userSiteListData
            .list"
          :key="index"
          @click="
            $router.push({
              name: 'Theme',
              params: {siteId: site.id}
            })
          "
        >
          <div v-if="site?.attachFiles?.url" class="site-logo">
            <img :src="site?.attachFiles?.url" />
          </div>
          <div v-else class="site-logo site-logo-text">
            {{ site.name.slice(0, 1) }}
          </div>
          <div class="site-info">
            <div class="solution-logo">
              <img v-if="site.solution?.logo" :src="site.solution?.logo.url" />
              <span v-else>{{ site.solution?.name }}</span>
            </div>
            <div class="site-name">{{ site.name }}</div>
          </div>
        </li>
        <div class="add-button" @click="siteAdd">
          <div class="add-icon">
            <div class="plus"></div>
          </div>
          <div class="add-text">사이트 추가</div>
        </div>
      </ul>
      <!-- 큐픽 제작 가이드 -->
      <div class="common-section" v-if="getUserProgressStepShow">
        <div class="title">
          큐픽 제작 가이드
          <tooltip
            top="30px"
            :text="`각 단계별 안내 내용을 참고하셔서 큐픽 테마 제작을 진행해주세요.<br />완료처리 된 내역을 ‘취소/삭제/해제’ 처리 될 경우 다시 미완료 상태로 전환됩니다.`"
          />
        </div>
        <div class="guide-wrap">
          <progress-guide></progress-guide>
        </div>
      </div>
      <!-- 최근 편집한 테마 -->
      <div class="common-section">
        <div class="title">최근 편집한 테마</div>
        <ul
          v-if="$store.state.ModuleTheme.userThemeListData.length"
          class="edit-theme-list"
        >
          <li
            v-for="(theme, index) in $store.state.ModuleTheme.userThemeListData
              ?.length > 3
              ? $store.state.ModuleTheme.userThemeListData.slice(0, 3)
              : $store.state.ModuleTheme.userThemeListData"
            :key="index"
          >
            <img
              v-if="theme?.thumbnail?.url"
              class="theme-bg"
              :src="theme?.thumbnail?.url"
            />
            <div v-else class="theme-bg no-theme-bg">
              <img
                :src="require('@/assets/images/introduction/ico_logo_gray.svg')"
                class="no-thumbnail-logo"
              />
            </div>
            <div class="theme-info">
              <div class="site-info">
                <div v-if="theme.userSite?.attachFiles?.url" class="site-logo">
                  <img :src="theme.userSite?.attachFiles?.url" />
                </div>
                <div v-else class="site-logo site-logo-text">
                  {{ theme.userSite.name.slice(0, 1) }}
                </div>
                <div class="solution-logo">
                  <img
                    v-if="theme.userSite?.solution?.logo"
                    :src="theme.userSite?.solution.logo.url"
                  />
                  <span v-else>{{ theme.userSite?.solution?.name }}</span>
                </div>
              </div>
              <div class="theme-name">{{ theme.name }}</div>
              <div class="theme-updated-at">
                최근 수정 :
                {{ theme.updatedAt | date }}
              </div>
              <router-link :to="{name: 'editor', params: {themeId: theme.id}}">
                <div class="edit-button">편집하기</div>
              </router-link>
            </div>
          </li>
        </ul>
        <div v-else class="edit-theme-empty">
          <img src="@/assets/images/admin/icon_empty.svg" />
          <p>최근 편집한 테마가 없습니다.</p>
        </div>
      </div>
      <!-- 새소식 -->
      <div class="common-section">
        <div class="title">새소식</div>
        <!-- pc -->
        <ul class="board-list board-list-pc">
          <li>
            <div class="board-title ga_a_23" @click="linkBoard(3)">
              공지사항
            </div>
            <div class="line"></div>
            <ul class="board-content">
              <li
                v-for="(
                  notice, index
                ) in $store.state.ModuleBoard.boardNoticeListData.list?.slice(
                  0,
                  3
                )"
                :key="index"
                @click="boardFindPage(notice.id, notice.boardId)"
                class="ga_a_20"
              >
                {{ notice.title }}
              </li>
            </ul>
          </li>
          <li>
            <div class="board-title ga_a_24" @click="linkBoard(2)">사용팁</div>
            <div class="line"></div>
            <ul class="board-content">
              <li
                v-for="(
                  usetip, index
                ) in $store.state.ModuleBoard.boardUsetipListData.list?.slice(
                  0,
                  3
                )"
                :key="index"
                @click="boardFindPage(usetip.id, usetip.boardId)"
                class="ga_a_21"
              >
                {{ usetip.title }}
              </li>
            </ul>
          </li>
          <li>
            <div class="board-title ga_a_25" @click="linkBoard(4)">FAQ</div>
            <div class="line"></div>
            <ul class="board-content">
              <li
                v-for="(
                  faq, index
                ) in $store.state.ModuleBoard.boardFaqListData.list?.slice(
                  0,
                  3
                )"
                :key="index"
                @click="boardFindPage(faq.id, faq.boardId)"
                class="ga_a_22"
              >
                {{ faq.title }}
              </li>
            </ul>
          </li>
        </ul>
        <!-- mobile -->
        <div v-swiper:mySwiper="swiperOption">
          <ul class="board-list board-list-mobile swiper-wrapper">
            <li class="swiper-slide">
              <div class="board-title ga_a_23" @click="linkBoard(3)">
                공지사항
              </div>
              <div class="line"></div>
              <ul class="board-content">
                <li
                  v-for="(
                    notice, index
                  ) in $store.state.ModuleBoard.boardNoticeListData.list?.slice(
                    0,
                    3
                  )"
                  :key="index"
                  @click="boardFindPage(notice.id, notice.boardId)"
                  class="ga_a_20"
                >
                  {{ notice.title }}
                </li>
              </ul>
            </li>
            <li class="swiper-slide">
              <div class="board-title ga_a_24" @click="linkBoard(2)">
                사용팁
              </div>
              <div class="line"></div>
              <ul class="board-content">
                <li
                  v-for="(
                    usetip, index
                  ) in $store.state.ModuleBoard.boardUsetipListData.list?.slice(
                    0,
                    3
                  )"
                  :key="index"
                  @click="boardFindPage(usetip.id, usetip.boardId)"
                  class="ga_a_21"
                >
                  {{ usetip.title }}
                </li>
              </ul>
            </li>
            <li class="swiper-slide">
              <div class="board-title ga_a_25" @click="linkBoard(4)">FAQ</div>
              <div class="line"></div>
              <ul class="board-content">
                <li
                  v-for="(
                    faq, index
                  ) in $store.state.ModuleBoard.boardFaqListData.list?.slice(
                    0,
                    3
                  )"
                  :key="index"
                  @click="boardFindPage(faq.id, faq.boardId)"
                  class="ga_a_22"
                >
                  {{ faq.title }}
                </li>
              </ul>
            </li>
          </ul>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <!-- 신규 테마 -->
      <div class="common-section">
        <div class="title">신규 테마</div>
        <ul class="new-theme-list">
          <li
            v-for="(theme, index) in getThemeCatalogData"
            :key="index"
            @click="linkNewTheme(theme.id)"
            :class="`ga_a_${99 + theme.id}`"
          >
            <img
              v-if="theme.product.thumbnail?.url"
              class="theme-bg"
              :src="theme.product.thumbnail?.url"
            />
            <div v-else class="theme-bg d-flex centered">
              <img
                :src="require('@/assets/images/introduction/ico_logo_gray.svg')"
                class="no-thumbnail-logo"
              />
            </div>
            <ul
              class="solution-logo"
              v-for="(solution, ix) in getSolutionListData"
              :key="ix"
            >
              <li v-if="solution.id === theme.solutionId">
                <img :src="solution.logo.url" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="footer">ⓒ QPICK</div>
    </div>
  </div>
</template>

<script>
import {directive} from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import {createNamespacedHelpers} from "vuex";
import ProgressGuide from "@/components/admin/ProgressGuide.vue";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleThemeProduct = createNamespacedHelpers("ModuleThemeProduct");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleUserSite = createNamespacedHelpers("ModuleUserSite");
const ModuleUser = createNamespacedHelpers("ModuleUser");

export default {
  components: {ProgressGuide},
  name: "Dashboard",
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        loop: true
      }
    };
  },
  computed: {
    ...ModuleUser.mapGetters(["getUserProgressStepShow"]),
    userId() {
      return this.getLoggedData.id;
    },
    ...ModuleAuth.mapGetters(["getLoggedData"]),
    ...ModuleTheme.mapGetters(["getThemeCatalogData"]),
    ...ModuleUserSite.mapGetters(["getSolutionListData"])
  },
  watch: {
    userId() {
      this.userSiteList();
      this.userThemeList();
    }
  },
  mounted() {
    this.solutionList();
    this.userSiteList();
    this.userThemeList();
    this.boardNoticeList();
    this.boardUsetipList();
    this.boardFaqList();
    this.newThemeList();
  },
  methods: {
    // 사이트 목록
    userSiteList() {
      const params = {
        userId: this.userId
      };
      this.$store.dispatch("ModuleUserSite/actUserSiteList", params);
    },
    // 최근 편집한 테마 목록
    userThemeList() {
      const params = {
        sortBy: "updatedAt-"
      };

      this.$store.dispatch("ModuleTheme/actUserThemeList", params);
    },
    // 공지사항 목록
    boardNoticeList() {
      this.$store.dispatch("ModuleBoard/actBoardNoticeList");
    },
    // 사용팁 목록
    boardUsetipList() {
      this.$store.dispatch("ModuleBoard/actBoardUsetipList");
    },
    // FAQ 목록
    boardFaqList() {
      this.$store.dispatch("ModuleBoard/actBoardFaqList");
    },
    // 신규 테마 목록
    newThemeList() {
      let payload = {};
      payload.display = 1;
      payload.sortBy = "id-";

      // this.actThemeProductList(payload).then(() => {});
      this.actThemeCatalog(payload).then(() => {});
    },
    //솔루션 목록
    solutionList() {
      this.actSolutionList({}).then(() => {});
    },
    // 게시글 이동
    boardFindPage(postId, boardId) {
      const windowReference = window.open();
      const params = {
        postId,
        boardId
      };
      this.$store
        .dispatch("ModuleBoard/actBoardFindPage", params)
        .then(response => {
          windowReference.location = `${process.env.VUE_APP_EXTERNAL_qpick_help_URL}/board-detail?id=${postId}&boardId=${boardId}&dtPage=${response.data.page}`;
        });
    },
    // 헬프센터 게시판 이동
    linkBoard(id) {
      window.open(
        `${process.env.VUE_APP_EXTERNAL_qpick_help_URL}/board?id=${id}`
      );
    },
    // 게시글 이동
    linkNewTheme(id) {
      window.open(`/introduction/preview?id=${id}&fileName=main`);
    },
    // 사이트 추가
    siteAdd() {
      if (this.$store.state.ModuleAuth.loggedData.sites.length > 0) {
        this.setAlert({
          isVisible: true,
          message: `베타 오픈 기간에는 사이트 생성이 1개로 제한됩니다.`
        });
      } else {
        // this.$router.push({name: "site"});
        let routeData = this.$router.resolve({
          name: "intro-theme-list",
          query: {
            id: 3,
            type: "site"
          }
        });
        window.open(routeData.href, "_blank");
      }
    },
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleThemeProduct.mapActions(["actThemeProductList"]),
    ...ModuleTheme.mapActions(["actThemeCatalog"]),
    ...ModuleUserSite.mapActions(["actSolutionList"])
  }
};
</script>

<style lang="scss">
$path: "~@/assets/images/admin/";

.admin-dashboard {
  .dashboard-wrap {
    max-width: 1248px;
    margin: 0 auto;
    padding: 0 16px;

    @media screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  // 회원 사이트 목록
  .site-list {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1023px) {
      padding: 0 16px;
    }

    li {
      width: 195px;
      height: 64px;
      margin-top: 16px;
      background: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      margin-right: 16px;
      padding: 8px 16px;
      box-sizing: border-box;
      display: flex;
      cursor: pointer;

      @media screen and (max-width: 1023px) {
        width: 182px;
      }

      @media screen and (max-width: 450px) {
        width: 48%;
        margin-right: 4%;
        &:nth-child(2n) {
          margin-right: 0px;
        }
      }

      .site-logo {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          max-width: 100%;
        }
      }

      .site-logo-text {
        background: #54c7a2;
        font-weight: 500;
        font-size: 24px;
        color: #fff;
      }

      .site-info {
        width: calc(100% - 56px);

        .solution-logo {
          img {
            width: 60px;
            vertical-align: -3px;
          }
        }

        .site-name {
          font-size: 14px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .add-button {
      margin-top: 16px;
      width: 195px;
      height: 64px;
      border: 1px dashed #d8dae5;
      border-radius: 8px;
      padding: 7px 16px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-content: center;

      @media screen and (max-width: 1023px) {
        width: 182px;
      }

      @media screen and (max-width: 450px) {
        width: 48%;
        min-width: 138px;
      }

      .add-icon {
        width: 48px;
        height: 48px;
        border: 1px solid #b7b7b7;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        aspect-ratio: 1/1;

        .plus {
          width: 16px;
          height: 16px;
          background-image: url($path + "icon_plus.svg");
        }
      }

      .add-text {
        font-weight: 500;
        font-size: 14px;
        color: #8d8d8d;
        line-height: 48px;
        white-space: nowrap;
      }
    }
  }

  .common-section {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin-top: 16px;
    padding: 24px 16px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 4px;
      margin-top: 16px;
    }

    ::-webkit-scrollbar-track {
      background: #f3f3f3;
      border-radius: 12px;
      padding: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #b7b7b7;
      border-radius: 12px;
    }

    @media screen and (max-width: 1023px) {
      box-shadow: none;
      border-radius: 0px;
      padding: 24px 0;
    }

    .title {
      position: relative;
      font-weight: 400;
      font-size: 16px;
      color: #309574;
      margin-bottom: 16px;

      @media screen and (max-width: 1023px) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  // 제작 가이드
  .guide-wrap {
    @media screen and (max-width: 1023px) {
      padding: 0 16px;
    }
  }

  // 최근 편집한 테마
  .edit-theme-list {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1140px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      padding: 0 16px;
      padding-bottom: 6px;
      margin-bottom: -6px;
    }

    li {
      width: 32.4%;
      border: 1px solid #dadada;
      border-radius: 8px;
      margin-right: 1.4%;
      display: flex;
      box-sizing: border-box;
      overflow: hidden;

      @media screen and (max-width: 1140px) {
        width: 339px;
        margin-right: 16px;
        flex: 0 0 auto;
      }

      &:last-child {
        margin-right: 0px;
      }

      .theme-bg {
        width: calc(100% - 205px);
        @media screen and (max-width: 1140px) {
          width: calc(100% - 189px);
        }
      }

      .no-theme-bg {
        background: #f0f0f0;
        min-height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .no-thumbnail-logo {
        width: 104px;
        text-align: center;
        @media screen and (min-width: 1024px) {
          width: 116px;
        }
      }

      .theme-info {
        width: 205px;
        position: relative;
        padding: 16px;
        box-sizing: border-box;
        @media screen and (max-width: 1140px) {
          width: 189px;
        }

        .site-info {
          display: flex;
          margin-bottom: 16px;

          .site-logo {
            width: 40px;
            height: 40px;
            margin-right: 8px;
            border: 1px solid #dcdcdc;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            box-sizing: border-box;

            img {
              max-width: 100%;
            }
          }

          .site-logo-text {
            background: #54c7a2;
            font-weight: 500;
            font-size: 24px;
            color: #fff;
          }

          .solution-logo {
            display: flex;
            align-items: center;

            img {
              width: 60px;
            }
          }
        }

        .theme-name {
          font-weight: 500;
          font-size: 16px;
          color: #484948;
          margin-bottom: 4px;
          line-height: 20px;
          max-height: 40px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        .theme-updated-at {
          font-weight: 400;
          font-size: 12px;
          color: #8d8d8d;
        }

        .edit-button {
          position: absolute;
          bottom: 16px;
          width: calc(100% - 32px);
          height: 22px;
          border: 1px solid #b7b7b7;
          color: #8d8d8d;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .edit-theme-empty {
    background: #f7f9fc;
    border-radius: 8px;
    text-align: center;
    padding: 30px 0;

    @media screen and (max-width: 1023px) {
      margin: 0 16px;
      padding: 60px 0;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: #309574;
      margin-top: 27px;
    }
  }

  // 새소식
  .board-list {
    display: flex;

    > li {
      width: 31.3%;
      margin-right: 3%;

      @media screen and (max-width: 1023px) {
        width: 100%;
        margin-right: 0px;
        padding: 0 16px;
        box-sizing: border-box;
      }

      &:last-child {
        margin-right: 0px;
      }

      .board-title {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 12px;
        cursor: pointer;
        display: inline-block;
      }

      .line {
        width: 100%;
        height: 1px;
        background: #f1f1f1;
        margin-bottom: 16px;
      }

      .board-content {
        li {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 16px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .board-list-pc {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  .board-list-mobile {
    display: none;

    @media screen and (max-width: 1023px) {
      display: flex;
      padding-bottom: 30px;
    }
  }

  .swiper-pagination {
    bottom: -2px;

    .swiper-pagination-bullet-active {
      background: #54c7a2;
    }
  }

  // 신규 테마
  .new-theme-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -16px;

    @media screen and (max-width: 1023px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      padding: 0 16px;
    }

    > li {
      width: 15.5%;
      margin-right: 1.4%;
      position: relative;
      margin-top: 35px;
      cursor: pointer;

      @media screen and (min-width: 1024px) {
        &:nth-child(6n) {
          margin-right: 0px;
        }
      }

      @media screen and (max-width: 1023px) {
        width: 150px;
        margin-right: 16px;
        flex: 0 0 auto;

        &:last-child {
          margin-right: 0px;
        }
      }

      .theme-bg {
        border-radius: 8px;
        width: 100%;
        background: #f0f0f0;
        min-height: 230px;
        @media screen and (min-width: 1024px) {
          min-height: 256px;
        }
      }

      .no-thumbnail-logo {
        width: 104px;
        @media screen and (min-width: 1024px) {
          width: 116px;
        }
      }

      .solution-logo {
        display: flex;
        position: absolute;
        top: -20px;
        left: 32px;

        li {
          width: 48px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dcdcdc;
          border-radius: 50%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          margin-left: -16px;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .footer {
    margin: 40px 0;
    color: #8d8d8d;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}
</style>
