var render = function () {
  var _vm$$store$state$Modu, _vm$$store$state$Modu2, _vm$$store$state$Modu3, _vm$$store$state$Modu4, _vm$$store$state$Modu5, _vm$$store$state$Modu6, _vm$$store$state$Modu7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "admin-dashboard"
  }, [_c('div', {
    staticClass: "dashboard-wrap"
  }, [_c('ul', {
    staticClass: "site-list"
  }, [_vm._l(_vm.$store.state.ModuleUserSite.userSiteListData.list, function (site, index) {
    var _site$attachFiles, _site$attachFiles2, _site$solution, _site$solution2, _site$solution3;

    return _c('li', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: 'Theme',
            params: {
              siteId: site.id
            }
          });
        }
      }
    }, [site !== null && site !== void 0 && (_site$attachFiles = site.attachFiles) !== null && _site$attachFiles !== void 0 && _site$attachFiles.url ? _c('div', {
      staticClass: "site-logo"
    }, [_c('img', {
      attrs: {
        "src": site === null || site === void 0 ? void 0 : (_site$attachFiles2 = site.attachFiles) === null || _site$attachFiles2 === void 0 ? void 0 : _site$attachFiles2.url
      }
    })]) : _c('div', {
      staticClass: "site-logo site-logo-text"
    }, [_vm._v(" " + _vm._s(site.name.slice(0, 1)) + " ")]), _c('div', {
      staticClass: "site-info"
    }, [_c('div', {
      staticClass: "solution-logo"
    }, [(_site$solution = site.solution) !== null && _site$solution !== void 0 && _site$solution.logo ? _c('img', {
      attrs: {
        "src": (_site$solution2 = site.solution) === null || _site$solution2 === void 0 ? void 0 : _site$solution2.logo.url
      }
    }) : _c('span', [_vm._v(_vm._s((_site$solution3 = site.solution) === null || _site$solution3 === void 0 ? void 0 : _site$solution3.name))])]), _c('div', {
      staticClass: "site-name"
    }, [_vm._v(_vm._s(site.name))])])]);
  }), _c('div', {
    staticClass: "add-button",
    on: {
      "click": _vm.siteAdd
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "add-text"
  }, [_vm._v("사이트 추가")])])], 2), _vm.getUserProgressStepShow ? _c('div', {
    staticClass: "common-section"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 큐픽 제작 가이드 "), _c('tooltip', {
    attrs: {
      "top": "30px",
      "text": "\uAC01 \uB2E8\uACC4\uBCC4 \uC548\uB0B4 \uB0B4\uC6A9\uC744 \uCC38\uACE0\uD558\uC154\uC11C \uD050\uD53D \uD14C\uB9C8 \uC81C\uC791\uC744 \uC9C4\uD589\uD574\uC8FC\uC138\uC694.<br />\uC644\uB8CC\uCC98\uB9AC \uB41C \uB0B4\uC5ED\uC744 \u2018\uCDE8\uC18C/\uC0AD\uC81C/\uD574\uC81C\u2019 \uCC98\uB9AC \uB420 \uACBD\uC6B0 \uB2E4\uC2DC \uBBF8\uC644\uB8CC \uC0C1\uD0DC\uB85C \uC804\uD658\uB429\uB2C8\uB2E4."
    }
  })], 1), _c('div', {
    staticClass: "guide-wrap"
  }, [_c('progress-guide')], 1)]) : _vm._e(), _c('div', {
    staticClass: "common-section"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("최근 편집한 테마")]), _vm.$store.state.ModuleTheme.userThemeListData.length ? _c('ul', {
    staticClass: "edit-theme-list"
  }, _vm._l(((_vm$$store$state$Modu = _vm.$store.state.ModuleTheme.userThemeListData) === null || _vm$$store$state$Modu === void 0 ? void 0 : _vm$$store$state$Modu.length) > 3 ? _vm.$store.state.ModuleTheme.userThemeListData.slice(0, 3) : _vm.$store.state.ModuleTheme.userThemeListData, function (theme, index) {
    var _theme$thumbnail, _theme$thumbnail2, _theme$userSite, _theme$userSite$attac, _theme$userSite2, _theme$userSite2$atta, _theme$userSite3, _theme$userSite3$solu, _theme$userSite4, _theme$userSite5, _theme$userSite5$solu;

    return _c('li', {
      key: index
    }, [theme !== null && theme !== void 0 && (_theme$thumbnail = theme.thumbnail) !== null && _theme$thumbnail !== void 0 && _theme$thumbnail.url ? _c('img', {
      staticClass: "theme-bg",
      attrs: {
        "src": theme === null || theme === void 0 ? void 0 : (_theme$thumbnail2 = theme.thumbnail) === null || _theme$thumbnail2 === void 0 ? void 0 : _theme$thumbnail2.url
      }
    }) : _c('div', {
      staticClass: "theme-bg no-theme-bg"
    }, [_c('img', {
      staticClass: "no-thumbnail-logo",
      attrs: {
        "src": require('@/assets/images/introduction/ico_logo_gray.svg')
      }
    })]), _c('div', {
      staticClass: "theme-info"
    }, [_c('div', {
      staticClass: "site-info"
    }, [(_theme$userSite = theme.userSite) !== null && _theme$userSite !== void 0 && (_theme$userSite$attac = _theme$userSite.attachFiles) !== null && _theme$userSite$attac !== void 0 && _theme$userSite$attac.url ? _c('div', {
      staticClass: "site-logo"
    }, [_c('img', {
      attrs: {
        "src": (_theme$userSite2 = theme.userSite) === null || _theme$userSite2 === void 0 ? void 0 : (_theme$userSite2$atta = _theme$userSite2.attachFiles) === null || _theme$userSite2$atta === void 0 ? void 0 : _theme$userSite2$atta.url
      }
    })]) : _c('div', {
      staticClass: "site-logo site-logo-text"
    }, [_vm._v(" " + _vm._s(theme.userSite.name.slice(0, 1)) + " ")]), _c('div', {
      staticClass: "solution-logo"
    }, [(_theme$userSite3 = theme.userSite) !== null && _theme$userSite3 !== void 0 && (_theme$userSite3$solu = _theme$userSite3.solution) !== null && _theme$userSite3$solu !== void 0 && _theme$userSite3$solu.logo ? _c('img', {
      attrs: {
        "src": (_theme$userSite4 = theme.userSite) === null || _theme$userSite4 === void 0 ? void 0 : _theme$userSite4.solution.logo.url
      }
    }) : _c('span', [_vm._v(_vm._s((_theme$userSite5 = theme.userSite) === null || _theme$userSite5 === void 0 ? void 0 : (_theme$userSite5$solu = _theme$userSite5.solution) === null || _theme$userSite5$solu === void 0 ? void 0 : _theme$userSite5$solu.name))])])]), _c('div', {
      staticClass: "theme-name"
    }, [_vm._v(_vm._s(theme.name))]), _c('div', {
      staticClass: "theme-updated-at"
    }, [_vm._v(" 최근 수정 : " + _vm._s(_vm._f("date")(theme.updatedAt)) + " ")]), _c('router-link', {
      attrs: {
        "to": {
          name: 'editor',
          params: {
            themeId: theme.id
          }
        }
      }
    }, [_c('div', {
      staticClass: "edit-button"
    }, [_vm._v("편집하기")])])], 1)]);
  }), 0) : _c('div', {
    staticClass: "edit-theme-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_empty.svg")
    }
  }), _c('p', [_vm._v("최근 편집한 테마가 없습니다.")])])]), _c('div', {
    staticClass: "common-section"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("새소식")]), _c('ul', {
    staticClass: "board-list board-list-pc"
  }, [_c('li', [_c('div', {
    staticClass: "board-title ga_a_23",
    on: {
      "click": function click($event) {
        return _vm.linkBoard(3);
      }
    }
  }, [_vm._v(" 공지사항 ")]), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "board-content"
  }, _vm._l((_vm$$store$state$Modu2 = _vm.$store.state.ModuleBoard.boardNoticeListData.list) === null || _vm$$store$state$Modu2 === void 0 ? void 0 : _vm$$store$state$Modu2.slice(0, 3), function (notice, index) {
    return _c('li', {
      key: index,
      staticClass: "ga_a_20",
      on: {
        "click": function click($event) {
          return _vm.boardFindPage(notice.id, notice.boardId);
        }
      }
    }, [_vm._v(" " + _vm._s(notice.title) + " ")]);
  }), 0)]), _c('li', [_c('div', {
    staticClass: "board-title ga_a_24",
    on: {
      "click": function click($event) {
        return _vm.linkBoard(2);
      }
    }
  }, [_vm._v("사용팁")]), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "board-content"
  }, _vm._l((_vm$$store$state$Modu3 = _vm.$store.state.ModuleBoard.boardUsetipListData.list) === null || _vm$$store$state$Modu3 === void 0 ? void 0 : _vm$$store$state$Modu3.slice(0, 3), function (usetip, index) {
    return _c('li', {
      key: index,
      staticClass: "ga_a_21",
      on: {
        "click": function click($event) {
          return _vm.boardFindPage(usetip.id, usetip.boardId);
        }
      }
    }, [_vm._v(" " + _vm._s(usetip.title) + " ")]);
  }), 0)]), _c('li', [_c('div', {
    staticClass: "board-title ga_a_25",
    on: {
      "click": function click($event) {
        return _vm.linkBoard(4);
      }
    }
  }, [_vm._v("FAQ")]), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "board-content"
  }, _vm._l((_vm$$store$state$Modu4 = _vm.$store.state.ModuleBoard.boardFaqListData.list) === null || _vm$$store$state$Modu4 === void 0 ? void 0 : _vm$$store$state$Modu4.slice(0, 3), function (faq, index) {
    return _c('li', {
      key: index,
      staticClass: "ga_a_22",
      on: {
        "click": function click($event) {
          return _vm.boardFindPage(faq.id, faq.boardId);
        }
      }
    }, [_vm._v(" " + _vm._s(faq.title) + " ")]);
  }), 0)])]), _c('div', {
    directives: [{
      name: "swiper",
      rawName: "v-swiper:mySwiper",
      value: _vm.swiperOption,
      expression: "swiperOption",
      arg: "mySwiper"
    }]
  }, [_c('ul', {
    staticClass: "board-list board-list-mobile swiper-wrapper"
  }, [_c('li', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "board-title ga_a_23",
    on: {
      "click": function click($event) {
        return _vm.linkBoard(3);
      }
    }
  }, [_vm._v(" 공지사항 ")]), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "board-content"
  }, _vm._l((_vm$$store$state$Modu5 = _vm.$store.state.ModuleBoard.boardNoticeListData.list) === null || _vm$$store$state$Modu5 === void 0 ? void 0 : _vm$$store$state$Modu5.slice(0, 3), function (notice, index) {
    return _c('li', {
      key: index,
      staticClass: "ga_a_20",
      on: {
        "click": function click($event) {
          return _vm.boardFindPage(notice.id, notice.boardId);
        }
      }
    }, [_vm._v(" " + _vm._s(notice.title) + " ")]);
  }), 0)]), _c('li', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "board-title ga_a_24",
    on: {
      "click": function click($event) {
        return _vm.linkBoard(2);
      }
    }
  }, [_vm._v(" 사용팁 ")]), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "board-content"
  }, _vm._l((_vm$$store$state$Modu6 = _vm.$store.state.ModuleBoard.boardUsetipListData.list) === null || _vm$$store$state$Modu6 === void 0 ? void 0 : _vm$$store$state$Modu6.slice(0, 3), function (usetip, index) {
    return _c('li', {
      key: index,
      staticClass: "ga_a_21",
      on: {
        "click": function click($event) {
          return _vm.boardFindPage(usetip.id, usetip.boardId);
        }
      }
    }, [_vm._v(" " + _vm._s(usetip.title) + " ")]);
  }), 0)]), _c('li', {
    staticClass: "swiper-slide"
  }, [_c('div', {
    staticClass: "board-title ga_a_25",
    on: {
      "click": function click($event) {
        return _vm.linkBoard(4);
      }
    }
  }, [_vm._v("FAQ")]), _c('div', {
    staticClass: "line"
  }), _c('ul', {
    staticClass: "board-content"
  }, _vm._l((_vm$$store$state$Modu7 = _vm.$store.state.ModuleBoard.boardFaqListData.list) === null || _vm$$store$state$Modu7 === void 0 ? void 0 : _vm$$store$state$Modu7.slice(0, 3), function (faq, index) {
    return _c('li', {
      key: index,
      staticClass: "ga_a_22",
      on: {
        "click": function click($event) {
          return _vm.boardFindPage(faq.id, faq.boardId);
        }
      }
    }, [_vm._v(" " + _vm._s(faq.title) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "swiper-pagination"
  })])]), _c('div', {
    staticClass: "common-section"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("신규 테마")]), _c('ul', {
    staticClass: "new-theme-list"
  }, _vm._l(_vm.getThemeCatalogData, function (theme, index) {
    var _theme$product$thumbn, _theme$product$thumbn2;

    return _c('li', {
      key: index,
      class: "ga_a_".concat(99 + theme.id),
      on: {
        "click": function click($event) {
          return _vm.linkNewTheme(theme.id);
        }
      }
    }, [(_theme$product$thumbn = theme.product.thumbnail) !== null && _theme$product$thumbn !== void 0 && _theme$product$thumbn.url ? _c('img', {
      staticClass: "theme-bg",
      attrs: {
        "src": (_theme$product$thumbn2 = theme.product.thumbnail) === null || _theme$product$thumbn2 === void 0 ? void 0 : _theme$product$thumbn2.url
      }
    }) : _c('div', {
      staticClass: "theme-bg d-flex centered"
    }, [_c('img', {
      staticClass: "no-thumbnail-logo",
      attrs: {
        "src": require('@/assets/images/introduction/ico_logo_gray.svg')
      }
    })]), _vm._l(_vm.getSolutionListData, function (solution, ix) {
      return _c('ul', {
        key: ix,
        staticClass: "solution-logo"
      }, [solution.id === theme.solutionId ? _c('li', [_c('img', {
        attrs: {
          "src": solution.logo.url
        }
      })]) : _vm._e()]);
    })], 2);
  }), 0)]), _c('div', {
    staticClass: "footer"
  }, [_vm._v("ⓒ QPICK")])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-icon"
  }, [_c('div', {
    staticClass: "plus"
  })]);
}]

export { render, staticRenderFns }