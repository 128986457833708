var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-guide-wrap"
  }, [_c('div', {
    staticClass: "bar-wrap"
  }, [_c('div', {
    staticClass: "bar-text"
  }, [_vm._v(_vm._s(_vm.guide.count) + "/5건 완료")]), _c('div', {
    staticClass: "bar"
  }, [_c('div', {
    staticClass: "active",
    style: "width:".concat(_vm.guide.count * 20, "%")
  })])]), _c('ul', {
    staticClass: "step-wrap"
  }, [_c('li', {
    class: {
      'step-more': _vm.guide.more === 1
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.guide.more === 1 ? _vm.guide.more = 0 : _vm.guide.more = 1;
      }
    }
  }, [_c('div', {
    staticClass: "step-title",
    class: {
      'step-complete': _vm.guide.complete[0]
    }
  }, [_c('div', {
    staticClass: "status"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[0] ? "사이트 및 테마가 생성되었습니다!" : "사이트 및 테마 생성하기") + " ")]), _c('button', {
    staticClass: "more-button"
  })]), _vm.guide.more === 1 ? _c('div', {
    staticClass: "step-section"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[0] ? "이제 쇼핑몰 디자인 테마 편집하거나 쇼핑몰 연동하기를 진행하세요." : "쇼핑몰 디자인 테마 제작과 관리를 시작해보세요.") + " ")]), !_vm.guide.complete[0] ? _c('div', {
    staticClass: "link-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._m(0)]) : _vm._e()]) : _vm._e()]), _c('li', {
    class: {
      'step-more': _vm.guide.more === 2
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.guide.more === 2 ? _vm.guide.more = 0 : _vm.guide.more = 2;
      }
    }
  }, [_c('div', {
    staticClass: "step-title",
    class: {
      'step-complete': _vm.guide.complete[1]
    }
  }, [_c('div', {
    staticClass: "status"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[1] ? "테마 편집이 완료되었습니다!" : "테마 편집하기") + " ")]), _c('button', {
    staticClass: "more-button"
  })]), _vm.guide.more === 2 ? _c('div', {
    staticClass: "step-section"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[1] ? "쇼핑몰 연동 및 사이트 연결까지 모두 완료 하시면 테마 내보내기를 이용하실 수 있습니다." : "화면구성을 변경하고 필요한 요소를 추가하여 편집 작업을 시작해보세요. 테마 편집 완료하신 후 저장버튼을 클릭해주세요.") + " ")]), !_vm.guide.complete[1] ? _c('div', {
    staticClass: "link-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._m(1)]) : _vm._e()]) : _vm._e()]), _c('li', {
    class: {
      'step-more': _vm.guide.more === 3
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.guide.more === 3 ? _vm.guide.more = 0 : _vm.guide.more = 3;
      }
    }
  }, [_c('div', {
    staticClass: "step-title",
    class: {
      'step-complete': _vm.guide.complete[2]
    }
  }, [_c('div', {
    staticClass: "status"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[2] ? "쇼핑몰 연동이 완료되었습니다!" : "쇼핑몰 솔루션 연동하기") + " ")]), _c('button', {
    staticClass: "more-button"
  })]), _vm.guide.more === 3 ? _c('div', {
    staticClass: "step-section"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[2] ? "솔루션 연동 관리 페이지에서 쇼핑몰 연동 정보 확인 및 사이트 연결하기를 진행하세요." : "쇼핑몰 솔루션에서 큐픽 서비스 연동을 진행하세요.") + " ")]), _c('div', {
    staticClass: "link-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.guide.complete[2] ? '/admin/solution' : 'https://help.qpicki.com/board-detail?id=25&boardId=2&dtPage=1',
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "gray-button"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[2] ? "솔루션 연동 관리" : "마이소호 연동 안내") + " ")])])])]) : _vm._e()]), _c('li', {
    class: {
      'step-more': _vm.guide.more === 4
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.guide.more === 4 ? _vm.guide.more = 0 : _vm.guide.more = 4;
      }
    }
  }, [_c('div', {
    staticClass: "step-title",
    class: {
      'step-complete': _vm.guide.complete[3]
    }
  }, [_c('div', {
    staticClass: "status"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[3] ? "사이트 연결이 완료되었습니다!" : "사이트 연결하기") + " ")]), _c('button', {
    staticClass: "more-button"
  })]), _vm.guide.more === 4 ? _c('div', {
    staticClass: "step-section"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[3] ? "이제 테마 목록 및 테마 편집 화면에서 큐픽 테마 내보내기를 사용하실 수 있습니다." : "쇼핑몰 솔루션으로 테마 내보내기를 하시려면 사이트 연결하기를 진행하세요.") + " ")]), !_vm.guide.complete[3] ? _c('div', {
    staticClass: "link-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._m(2), _vm._m(3)]) : _vm._e()]) : _vm._e()]), _c('li', {
    class: {
      'step-more': _vm.guide.more === 5
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.guide.more === 5 ? _vm.guide.more = 0 : _vm.guide.more = 5;
      }
    }
  }, [_c('div', {
    staticClass: "step-title",
    class: {
      'step-complete': _vm.guide.complete[4]
    }
  }, [_c('div', {
    staticClass: "status"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[4] ? "테마 내보내기가 완료되었습니다!" : "나의 쇼핑몰로 테마 내보내기") + " ")]), _c('button', {
    staticClass: "more-button"
  })]), _vm.guide.more === 5 ? _c('div', {
    staticClass: "step-section"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.guide.complete[4] ? "이제 쇼핑몰 솔루션에서 큐픽 테마를 적용하실 수 있습니다. 적용 전 테스트 확인 후 적용하시길 권장드립니다." : "솔루션 연동 관리에서 사이트 연결 상태를 확인 후 쇼핑몰 솔루션으로 테마 내보내기를 진행하세요.") + " ")]), !_vm.guide.complete[4] ? _c('div', {
    staticClass: "link-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._m(4), _vm._m(5)]) : _vm._e()]) : _vm._e()])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "/introduction/theme-list?id=3",
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "gray-button"
  }, [_vm._v("테마 만들기")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "https://help.qpicki.com/board-detail?id=10&boardId=1&dtPage=1",
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "gray-button"
  }, [_vm._v("테마 에디터 안내")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "https://help.qpicki.com/board-detail?id=9&boardId=1&dtPage=1",
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "white-button"
  }, [_vm._v("솔루션 연동 관리 안내")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "/admin/solution",
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "gray-button"
  }, [_vm._v("사이트 연결하기")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "https://help.qpicki.com/board-detail?id=13&boardId=1&dtPage=1",
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "white-button"
  }, [_vm._v("테마 내보내기 안내")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "/admin/solution",
      "target": "_blank"
    }
  }, [_c('button', {
    staticClass: "gray-button"
  }, [_vm._v("솔루션 연동 관리")])]);
}]

export { render, staticRenderFns }