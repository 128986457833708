<template>
  <div class="progress-guide-wrap">
    <div class="bar-wrap">
      <div class="bar-text">{{ guide.count }}/5건 완료</div>
      <div class="bar">
        <div class="active" :style="`width:${guide.count * 20}%`"></div>
      </div>
    </div>
    <ul class="step-wrap">
      <!-- step1 -->
      <li
        :class="{'step-more': guide.more === 1}"
        @click.stop="guide.more === 1 ? (guide.more = 0) : (guide.more = 1)"
      >
        <div class="step-title" :class="{'step-complete': guide.complete[0]}">
          <div class="status"></div>
          <div class="text">
            {{
              guide.complete[0]
                ? "사이트 및 테마가 생성되었습니다!"
                : "사이트 및 테마 생성하기"
            }}
          </div>
          <button class="more-button"></button>
        </div>
        <div class="step-section" v-if="guide.more === 1">
          <div class="text">
            {{
              guide.complete[0]
                ? "이제 쇼핑몰 디자인 테마 편집하거나 쇼핑몰 연동하기를 진행하세요."
                : "쇼핑몰 디자인 테마 제작과 관리를 시작해보세요."
            }}
          </div>
          <div class="link-button" v-if="!guide.complete[0]" @click.stop>
            <a href="/introduction/theme-list?id=3" target="_blank">
              <button class="gray-button">테마 만들기</button>
            </a>
          </div>
        </div>
      </li>
      <!-- step2 -->
      <li
        :class="{'step-more': guide.more === 2}"
        @click.stop="guide.more === 2 ? (guide.more = 0) : (guide.more = 2)"
      >
        <div class="step-title" :class="{'step-complete': guide.complete[1]}">
          <div class="status"></div>
          <div class="text">
            {{
              guide.complete[1]
                ? "테마 편집이 완료되었습니다!"
                : "테마 편집하기"
            }}
          </div>
          <button class="more-button"></button>
        </div>
        <div class="step-section" v-if="guide.more === 2">
          <div class="text">
            {{
              guide.complete[1]
                ? "쇼핑몰 연동 및 사이트 연결까지 모두 완료 하시면 테마 내보내기를 이용하실 수 있습니다."
                : "화면구성을 변경하고 필요한 요소를 추가하여 편집 작업을 시작해보세요. 테마 편집 완료하신 후 저장버튼을 클릭해주세요."
            }}
          </div>
          <div class="link-button" v-if="!guide.complete[1]" @click.stop>
            <a
              href="https://help.qpicki.com/board-detail?id=10&boardId=1&dtPage=1"
              target="_blank"
            >
              <button class="gray-button">테마 에디터 안내</button>
            </a>
          </div>
        </div>
      </li>
      <!-- step3 -->
      <li
        :class="{'step-more': guide.more === 3}"
        @click.stop="guide.more === 3 ? (guide.more = 0) : (guide.more = 3)"
      >
        <div class="step-title" :class="{'step-complete': guide.complete[2]}">
          <div class="status"></div>
          <div class="text">
            {{
              guide.complete[2]
                ? "쇼핑몰 연동이 완료되었습니다!"
                : "쇼핑몰 솔루션 연동하기"
            }}
          </div>
          <button class="more-button"></button>
        </div>
        <div class="step-section" v-if="guide.more === 3">
          <div class="text">
            {{
              guide.complete[2]
                ? "솔루션 연동 관리 페이지에서 쇼핑몰 연동 정보 확인 및 사이트 연결하기를 진행하세요."
                : "쇼핑몰 솔루션에서 큐픽 서비스 연동을 진행하세요."
            }}
          </div>
          <div class="link-button" @click.stop>
            <a
              :href="
                guide.complete[2]
                  ? '/admin/solution'
                  : 'https://help.qpicki.com/board-detail?id=25&boardId=2&dtPage=1'
              "
              target="_blank"
            >
              <button class="gray-button">
                {{
                  guide.complete[2] ? "솔루션 연동 관리" : "마이소호 연동 안내"
                }}
              </button>
            </a>
          </div>
        </div>
      </li>
      <!-- step4 -->
      <li
        :class="{'step-more': guide.more === 4}"
        @click.stop="guide.more === 4 ? (guide.more = 0) : (guide.more = 4)"
      >
        <div class="step-title" :class="{'step-complete': guide.complete[3]}">
          <div class="status"></div>
          <div class="text">
            {{
              guide.complete[3]
                ? "사이트 연결이 완료되었습니다!"
                : "사이트 연결하기"
            }}
          </div>
          <button class="more-button"></button>
        </div>
        <div class="step-section" v-if="guide.more === 4">
          <div class="text">
            {{
              guide.complete[3]
                ? "이제 테마 목록 및 테마 편집 화면에서 큐픽 테마 내보내기를 사용하실 수 있습니다."
                : "쇼핑몰 솔루션으로 테마 내보내기를 하시려면 사이트 연결하기를 진행하세요."
            }}
          </div>
          <div class="link-button" v-if="!guide.complete[3]" @click.stop>
            <a
              href="https://help.qpicki.com/board-detail?id=9&boardId=1&dtPage=1"
              target="_blank"
            >
              <button class="white-button">솔루션 연동 관리 안내</button>
            </a>
            <a href="/admin/solution" target="_blank">
              <button class="gray-button">사이트 연결하기</button>
            </a>
          </div>
        </div>
      </li>
      <!-- step5 -->
      <li
        :class="{'step-more': guide.more === 5}"
        @click.stop="guide.more === 5 ? (guide.more = 0) : (guide.more = 5)"
      >
        <div class="step-title" :class="{'step-complete': guide.complete[4]}">
          <div class="status"></div>
          <div class="text">
            {{
              guide.complete[4]
                ? "테마 내보내기가 완료되었습니다!"
                : "나의 쇼핑몰로 테마 내보내기"
            }}
          </div>
          <button class="more-button"></button>
        </div>
        <div class="step-section" v-if="guide.more === 5">
          <div class="text">
            {{
              guide.complete[4]
                ? "이제 쇼핑몰 솔루션에서 큐픽 테마를 적용하실 수 있습니다. 적용 전 테스트 확인 후 적용하시길 권장드립니다."
                : "솔루션 연동 관리에서 사이트 연결 상태를 확인 후 쇼핑몰 솔루션으로 테마 내보내기를 진행하세요."
            }}
          </div>
          <div class="link-button" v-if="!guide.complete[4]" @click.stop>
            <a
              href="https://help.qpicki.com/board-detail?id=13&boardId=1&dtPage=1"
              target="_blank"
            >
              <button class="white-button">테마 내보내기 안내</button>
            </a>
            <a href="/admin/solution" target="_blank">
              <button class="gray-button">솔루션 연동 관리</button>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  props: {},
  data() {
    return {
      guide: {
        count: 0,
        more: 1,
        complete: [false, false, false, false, false]
      }
    };
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoggedData"]),
    userId() {
      return this.getLoggedData.id;
    }
  },
  watch: {
    userId() {
      this.userProgreeStep();
    }
  },
  mounted() {
    this.userProgreeStep();
  },
  methods: {
    // 제작 가이드
    userProgreeStep() {
      const params = {
        userId: this.userId
      };
      this.$store
        .dispatch("ModuleUser/actUserProgressStep", params)
        .then(response => {
          const data = response.data;
          // step1 complete
          if (data.userSite && data.userTheme) {
            this.guide.complete[0] = true;
          }
          // step2 complete
          if (data.userTheme && data.userThemeSaveHistory) {
            this.guide.complete[1] = true;
          }
          // step3 complete
          if (data.userSolution) {
            this.guide.complete[2] = true;
          }
          // step4 complete
          if (data.connectSiteToSolution) {
            this.guide.complete[3] = true;
          }
          // step5 complete
          if (data.userThemeExport) {
            this.guide.complete[4] = true;
          }

          const count = this.guide.complete.filter(v => v === true);
          this.guide.count = count.length;

          const more = this.guide.complete.findIndex(v => v === false);
          this.guide.more = more + 1;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
// 큐픽 제작 가이드
.progress-guide-wrap {
  .bar-wrap {
    display: flex;
    align-items: center;

    .bar-text {
      font-weight: 400;
      font-size: 12px;
      color: #8d8d8d;
      width: 56px;
    }

    .bar {
      margin-left: 8px;
      height: 4px;
      background: #f0f0f0;
      border-radius: 2px;
      width: calc(100% - 64px);

      .active {
        background: #54c7a2;
        border-radius: 2px;
        height: 4px;
      }
    }
  }

  .step-wrap {
    margin-top: 16px;

    li {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      padding: 23px 16px;
      margin-bottom: 8px;
      background: #fff;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }

      .step-title {
        display: flex;
        align-items: center;

        .status {
          width: 23px;
          height: 23px;
          background-image: url("~@/assets/images/admin/icon_complete_no.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: contain;
        }

        .text {
          font-weight: 500;
          font-size: 16px;
          margin-left: 8px;
        }

        .more-button {
          display: flex;
          margin-left: auto;
          margin-right: 8px;
          width: 9px;
          height: 6px;
          background-image: url("~@/assets/images/common/bul_arr_down.svg");
          background-repeat: no-repeat;
          background-position: 0 0;
        }
      }

      .step-complete {
        .text {
          color: #8d8d8d;
        }

        .status {
          background-image: url("~@/assets/images/admin/icon_complete.svg") !important;
        }
      }

      .step-section {
        margin-top: 16px;
        display: flex;
        align-items: center;
        margin-left: 30px;

        @media screen and (max-width: 1023px) {
          margin-left: 0px;
          display: block;
        }

        .text {
          font-weight: 400;
          font-size: 16px;
          color: #484948;
        }

        .link-button {
          margin-left: auto;
          padding-left: 20px;
          display: flex;
          @media screen and (max-width: 1023px) {
            margin-top: 16px;
            padding-left: 0;
            display: block;
          }

          button {
            width: 145px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 2px;
            font-weight: 500;
            font-size: 14px;
            @media screen and (max-width: 1023px) {
              width: 100%;
            }
          }

          .white-button {
            border: 1px solid #d8dae5;
            color: #606060;
            margin-right: 8px;
            @media screen and (max-width: 1023px) {
              margin-right: 0px;
              margin-bottom: 4px;
            }
          }

          .gray-button {
            background: #5d616b;
            color: #fff;
          }
        }
      }
    }

    .step-more {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

      .more-button {
        background-image: url("~@/assets/images/common/bul_arr_up.svg") !important;
      }
    }
  }
}
</style>
